
function Footer() {
    return <div className="container">
        <div className="pt-[200px] pb-[200px] flex w-full flex-wrap items-center justify-between">
            <div className="max-w-[500px]">
                <div className="title font-bold">Get In Touch</div>
                <div className="opacity-[70%] max-w-[500px]">
                    Don't hesitate to contact me with your creative endeavors or business inquiries. I look forward to hearing from you and exploring the possibilities.
                </div>

            </div>

            <div className="mt-[50px] flex items-center gap-5 flex-col">
                <a className="btn-grad" href="mailto:hello@semma.dev">Send me an Email</a>
                <div className="items-center right-0 text-[20px] flex md:flex mt-5">
                    <div className="md:text-lg text-sm">Contact me on</div>

                    <div className="line w-[50px] h-[2px] bg-[black] mr-8 ml-8"></div>

                    <div className="flex items-center justify-center gap-4 text-[25px]">
                        <a href="https://www.instagram.com/semma.dev/" target="_blank">
                            <i className="fa-brands fa-instagram"></i>
                        </a>
                        <a href="https://github.com/ahmtvc" target="_blank">
                            <i className="fa-brands fa-github"></i>
                        </a>
                        <a href="https://t.me/ahmtvc" target="_blank">
                            <i className="fa-brands fa-telegram"></i>
                        </a>
                    </div>
                </div>
            </div>

            <div className="flex justify-end w-full items-center text-[14px] opacity-[60%] mt-[100px]">
                Copyright &copy; 2024 by semma.dev 
            </div>
        </div>
    </div>
}

export default Footer;