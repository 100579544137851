import React, { useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  animated: {
    opacity: 0,
    transition: 'all 2s',
    willChange: 'opacity, transform', // Optimize for changes
  },
  fadeIn: {
    animationDuration: '2s',
    animationName: '$fadeIn',
  },
  popFromLeft: {
    animationDuration: '2s',
    animationName: '$popFromLeft',
  },
  popFromRight: {
    animationDuration: '2s',
    animationName: '$popFromRight',
  },
  popFromBottom: {
    animationDuration: '2s',
    animationName: '$popFromBottom',
  },
  popFromTop: {
    animationDuration: '2s',
    animationName: '$popFromTop',
  },
  popRotateClockwise: {
    animationDuration: '2s',
    animationName: '$popRotateClockwise',
  },
  popRotateCounterClockwise: {
    animationDuration: '2s',
    animationName: '$popRotateCounterClockwise',
  },
  popScale: {
    animationDuration: '2s',
    animationName: '$popScale',
  },
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  '@keyframes popFromLeft': {
    from: {
      transform: 'translateX(-50px)',
      opacity: 0,
    },
    to: {
      transform: 'translateX(0)',
      opacity: 1,
    },
  },
  '@keyframes popFromRight': {
    from: {
      transform: 'translateX(50px)',
      opacity: 0,
    },
    to: {
      transform: 'translateX(0)',
      opacity: 1,
    },
  },
  '@keyframes popFromBottom': {
    from: {
      transform: 'translateY(50px)',
      opacity: 0,
    },
    to: {
      transform: 'translateY(0)',
      opacity: 1,
    },
  },
  '@keyframes popFromTop': {
    from: {
      transform: 'translateY(-50px)',
      opacity: 0,
    },
    to: {
      transform: 'translateY(0)',
      opacity: 1,
    },
  },
  '@keyframes popRotateClockwise': {
    from: {
      transform: 'rotate(-90deg) scale(0)',
      opacity: 0,
    },
    to: {
      transform: 'rotate(0) scale(1)',
      opacity: 1,
    },
  },
  '@keyframes popRotateCounterClockwise': {
    from: {
      transform: 'rotate(90deg) scale(0)',
      opacity: 0,
    },
    to: {
      transform: 'rotate(0) scale(1)',
      opacity: 1,
    },
  },
  '@keyframes popScale': {
    from: {
      transform: 'scale(0)',
      opacity: 0,
    },
    to: {
      transform: 'scale(1)',
      opacity: 1,
    },
  },
});

const getRandomAnimation = () => {
  const animations = [
    'popFromLeft',
    'popFromRight',
    //'popFromBottom',Selmir's CV -  (2024 CV)
     'popFromTop',
    //'popRotateClockwise',
    //'popRotateCounterClockwise',
    //'popScale',
  ];
  const randomIndex = Math.floor(Math.random() * animations.length);
  return animations[randomIndex];
};

const Animated = ({ children, className, ...props }) => {
  const classes = useStyles();
  const [animationClass, setAnimationClass] = useState(`${classes.animated}`);

  const animatedRef = useRef(null);

  useEffect(() => {
    const screenWidth = window.innerWidth;

    /*Disable animations on phone until flickering is fixed*/
    if (screenWidth <= 600) {
      animatedRef.current.style.opacity = 1;
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const animation = getRandomAnimation();
            animatedRef.current.style.opacity = 1;
            setAnimationClass(`${classes[animation]}`);
          } else {
            animatedRef.current.style.opacity = 0;
            setAnimationClass(``);
          }
        });
      },
      { threshold: 0.5 }
    );

    observer.observe(animatedRef.current);

    return () => {
      animatedRef.current.style.opacity = 0;
      observer.disconnect();
    };
  }, [classes]);

  return (
    <div
      ref={animatedRef}
      className={`${animationClass} ${className}`}
      style={{ opacity: 0 }}
      {...props}
    >
      {children}
    </div>
  );
};

export default Animated;
