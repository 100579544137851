
function HeroSection() {

    return <div className="container mt-[100px] z-2">
        <div className="flex justify-between pt-[100px] pb-[100px] flex-col md:flex-row">
            <div className="left-side md:max-w-[60%]">
                <div className="preTitle">
                    Welcome
                </div>

                <div className="title">
                    From Concept to <div className="text-primary-color inline-block">Cutting-Edge Solutions</div>
                </div>

                <div className="mt-5">I'm Selmir, aka "Sema", a creative <span className="inline-block text-primary-color font-semibold">Full-Stack Developer</span>. I've been helping businesses to solve their problems with my work for over <span className="inline-block text-primary-color font-semibold">5 years</span>.
                </div>


                <div className="mt-[50px] flex items-center gap-5 flex-wrap justify-start">
                   <a className="btn-grad" href="mailto:hello@semma.dev">Send me an Email</a>
                   
                   <div className="flex items-center gap-[5px]">
                        <a className="p-[10px] text-[17px]" href="/">View Portfolio</a>
                        <i className="fa-solid fa-arrow-up-right-from-square"></i>
                   </div>

                </div>
            </div>

            <div className="items-center rotate-90 right-0 text-[20px] hidden md:flex">
                <div className="">Contact me on</div>

                <div className="line w-[50px] h-[2px] bg-[black] mr-8 ml-8"></div>

                <div className="flex items-center justify-center gap-2">
                    <a href="https://www.instagram.com/semma.dev/" target="_blank">
                        <i className="fa-brands fa-instagram"></i>
                    </a>
                    <a href="https://github.com/ahmtvc" target="_blank">
                        <i className="fa-brands fa-github"></i>
                    </a>
                    <a href="https://t.me/ahmtvc" target="_blank">
                        <i className="fa-brands fa-telegram"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
}

export default HeroSection;