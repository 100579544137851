import { Blockquote, Rating } from '@mantine/core';


export default function ClientReviews()  {
    const icon = <div className="text-[#2a92bb]"><i className="fa-solid fa-user-large"></i></div>;

    const reviews = [
        {
            desc: "Selmir is a stellar freelancer. I would recommend him to anyone. Serious, reliable with great communication. A pleasure to work with him every step of the way.",
            client: "Graham Shillington",
            location: "South Africa"
        },
        {
            desc: "Working with Selmir has been nothing short of amazing. Very clear communication from the start, Selmir is very patient and made sure everything was in order for each milestone. I highly recommend his work to anyone that wants quality results.",
            client: "Guillaume Hucheloup",
            location: "Thailand"
        },
        {
            desc: "Wonderful developer. Dedicated and experienced. Was pleasure working with.",
            client: "Finj Technologies Ltd",
            location: "Bulgaria, Sofia"
        },
        {
            desc: "Great work. If you are looking for a genius developer look no further!!! Selmir outperformed my expectations in many ways during our contract. I will come back soon",
            client: "Akilea SE",
            location: "United States, Boynton Beach"
        },
        {
            desc: "Selmir completed the work same-day and was able to deliver on all the job requirements. He's a professional.",
            client: "Daniel Shargorodsky",
            location: "United States, Kings County"
        },
        {
            desc: "Selmir went above and beyond what was requested of him and has been an absolute pleasure to work with. I look forward to working with him again in the future.",
            client: "Conner Dunda",
            location: "United States, Plantsville"
        }

    ]

    return <div className="container" id="clientsreviews">

        <div className="flex flex-col mt-20">
            <div className="preTitle">
            What clients say about me
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 p-2">
                {reviews.map(review => {
                    return <Blockquote key={review.client} color="blue" radius="xl" iconSize={44} cite={`– ${review.client} from ${review.location}`} icon={icon} mt="xl">
                        {review.desc}
                        <div><br /><div className="flex items-center gap-2"><Rating defaultValue={5} color="blue" readOnly/></div></div>
                    </Blockquote>
                })}
            </div>
        </div>
    </div>
}