import React from 'react';
import { Collapse } from '@mantine/core';

const faqs = [
  {
    question: "What services do you offer as a Full-Stack Developer?",
    answer: "I offer end-to-end web development services, including both front-end and back-end development. I specialize in creating dynamic user interfaces using HTML, CSS, and React.js, while ensuring robust back-end functionality with Java and Spring Boot. I also provide database integration using MongoDB, MySQL, and RedisDB, along with custom API development and Discord bot services."
  },
  {
    question: "What technologies do you specialize in?",
    answer: "I specialize in a range of technologies, including Java, Spring Boot, React.js, HTML, CSS, MongoDB, MySQL, RedisDB, and API development. I also have expertise in Discord API and bot development for interactive community experiences."
  },
  {
    question: "Can you handle both front-end and back-end development?",
    answer: "Yes, as a Full-Stack Developer, I handle both front-end and back-end development. From designing intuitive, user-friendly interfaces to building secure, scalable back-end systems, I ensure that all aspects of your application are cohesive and efficient."
  },
  {
    question: "How do you ensure that my project meets its goals?",
    answer: "I work closely with you to understand your project’s unique requirements, ensuring that every solution is tailored to your needs. I emphasize continuous communication, flexibility, and adapting to your feedback to deliver a solution that aligns with your vision."
  },
  {
    question: "What is your approach to project management and communication?",
    answer: "I use a collaborative approach to project management, keeping you informed and involved throughout the development process. Regular updates, milestones, and open communication ensure that we stay on track and address any issues promptly."
  },
  {
    question: "How do you handle project deadlines and deliverables?",
    answer: "I set realistic deadlines and milestones based on the scope of your project and work diligently to meet them. If any adjustments are needed, I communicate them clearly and work with you to ensure that the project remains on track."
  },
  {
    question: "Do you offer ongoing support and maintenance?",
    answer: "Yes, I provide ongoing support and maintenance to ensure your web application or system remains up-to-date and fully functional. Whether it’s implementing new features, fixing bugs, or optimizing performance, I’m here to help post-launch."
  },
];

const FAQSection = () => {
  const [opened, setOpened] = React.useState(null);

  const handleToggle = (index) => {
    setOpened(opened === index ? null : index);
  };

  return (
    <div className="mx-auto p-4 mt-10 flex flex-col justify-start items-center">
      <h2 className="title text-center">FAQ</h2>
      
      {faqs.map((faq, index) => (
        <div key={index} className="mb-4 w-full max-w-[600px]">
          <button
            className="w-full text-left p-4 bg-[#74b1fb16] rounded-lg"
            onClick={() => handleToggle(index)}
          >
            <h3 className="text-lg font-semibold">{faq.question}</h3>
          </button>
          <Collapse in={opened === index}>
            <div className="p-4 bg-[#74b1fb16] rounded-lg mt-2 w-full max-w-[600px]">
              <p>{faq.answer}</p>
            </div>
          </Collapse>
        </div>
      ))}
    </div>
  );
};

export default FAQSection;
