
function Navigation() {

    return <div className="pt-[20px] pr-[20px] pl-[20px] flex items-center justify-between flex-col md:flex-row gap-5 md:gap-0 z-2">

            <div href="/" className="flex items-center text-[20px]">
                <img src="../logo/logo.png" alt="Logo image" className="max-w-[50px]" />
                <div className="text-primary-color font-semibold">Semma Dev Solutions</div>
            </div>

            <div className="items-center justify-center gap-5 md:mr-[15%] w-full md:w-fit hidden md:flex text-primary-color">

                <a href="#whatioffer" className="flex items-center gap-2 hover:underline hover:text-primary-color">
                    <i className="fa-solid fa-briefcase"></i>
                    <div>What I offer</div>
                </a>

                <a href="#clientsreviews" className="flex items-center gap-2 hover:underline hover:text-primary-color">
                    <i className="fa-solid fa-users"></i>
                    <div>What clients say about me</div>
                </a>
            </div>

            <img src="./wave.svg" alt="Background wave" className="fixed top-0 z-[-1] left-0 white"></img>

    </div>
}

export default Navigation;