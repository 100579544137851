import { Divider, Highlight } from "@mantine/core";
import Animated from "../../Animated";

export default function WhatIOffer() {

    const hightlightStyle = {
        backgroundImage:
          'linear-gradient(45deg, var(--mantine-color-cyan-5), var(--mantine-color-indigo-5))',
        fontWeight: 700,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      }

    return <div className="container" id="whatioffer">
        <div className="flex flex-col gap-10">
            <Highlight className="text-xl" highlightStyles={hightlightStyle} highlight={["Full-Stack Developer", "HTML", "CSS", "React.js", "front-end", "back-end", "5 years"]}>As a seasoned Full-Stack Developer, I provide comprehensive solutions to meet your digital needs. With over 5 years of experience, I specialize in both front-end and back-end development, ensuring a cohesive and efficient application from start to finish. I harness the power of Java and Spring Boot for robust backend systems, while using HTML, CSS, and React.js to create dynamic and user-friendly interfaces.</Highlight>     

            <Highlight className="text-xl" highlightStyles={hightlightStyle} highlight={["MongoDB", "MySQL", "RedisDB", "APIs", "Discord", "database integration"]}>My expertise extends to database integration, where I utilize MongoDB, MySQL, and RedisDB to manage and optimize your data. I also excel in developing and integrating APIs, ensuring seamless communication and functionality across your applications. For those looking to enhance their presence on Discord, I offer specialized skills in Discord API and bot development, creating engaging and interactive experiences for your community.</Highlight>                

            <Highlight className="text-xl" highlightStyles={hightlightStyle} highlight={["cutting-edge solutions", "tailored", "reality", "exceptional"]}>I am committed to adapting to your project’s unique requirements, continuously learning new technologies to provide cutting-edge solutions. Whether you need a tailored web application, a streamlined backend system, or an interactive bot, I am here to turn your vision into reality. Let’s collaborate to create something exceptional!</Highlight>                  
        </div>
    </div>
}