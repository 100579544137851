import { useRef } from 'react';
import Autoplay from 'embla-carousel-autoplay';
import { Carousel } from '@mantine/carousel';
import Animated from "../../Animated";

function SkillsSection() {
    const autoplay = useRef(Autoplay({ delay: 1500 , jump: false, loop: true}));


    return <div className="container">
        <Animated className="wrapper">

            <Carousel
                slideSize="33.333333%"
                slideGap="md"
                height={200}
                loop={true}
                align={"start"}
                draggable={false}
                withControls={false}
                plugins={[autoplay.current]}
                >
      
                <Carousel.Slide className="flex flex-col gap-[20px] max-w-[30%]">
                    <div className="flex items-center gap-2">
                        <i className="fas fa-coffee text-[20px] text-[#bdabab]"></i>
                        <div className="font-semibold text-[20px]">Backend Development</div>
                    </div>

                    <div>
                        Expertise in Java for robust backend development.
                    </div>
                </Carousel.Slide>

                <Carousel.Slide className="flex flex-col gap-[20px] max-w-[30%]">
                    <div className="flex items-center gap-2">
                        <i className="fab fa-css3-alt text-[23px] text-[#eac83d]"></i>
                        <div className="font-semibold text-[20px]">Frontend Development</div>
                    </div>

                    <div>
                    Specializes in frontend technologies for dynamic user experiences.
                    </div>
                </Carousel.Slide>

                <Carousel.Slide className="flex flex-col gap-[20px] max-w-[30%]">
                    <div className="flex items-center gap-2">
                        <i className="fas fa-database text-[20px] text-[#9d73ff]"></i>
                        <div className="font-semibold text-[20px]">Database Management</div>
                    </div>

                    <div>
                        Manages diverse databases like MongoDB and Redis.
                    </div>
                </Carousel.Slide>

                <Carousel.Slide className="flex flex-col gap-[20px] max-w-[30%]">
                    <div className="flex items-center gap-2">
                        <i className="fas fa-globe text-[20px] text-[blue]"></i>
                        <div className="font-semibold text-[20px]">Web Scraping</div>
                    </div>

                    <div>
                        Utilizes web scraping for data extraction and analysis.
                    </div>
                </Carousel.Slide>

                <Carousel.Slide className="flex flex-col gap-[20px] max-w-[30%]">
                    <div className="flex items-center gap-2">
                        <i className="fas fa-network-wired text-[20px] text-[#36b833]"></i>
                        <div className="font-semibold text-[20px]">Restful APIs</div>
                    </div>

                    <div>
                        Architects RESTful APIs for seamless data communication.
                    </div>
                </Carousel.Slide>

                <Carousel.Slide className="flex flex-col gap-[20px] max-w-[30%]">
                    <div className="flex items-center gap-2">
                        <i className="fab fa-js-square text-[20px] text-[#38b0c6]"></i>
                        <div className="font-semibold text-[20px]">React.js</div>
                    </div>

                    <div>
                        Creates responsive and interactive interfaces using React.js
                    </div>
                </Carousel.Slide>

                <Carousel.Slide className="flex flex-col gap-[20px] max-w-[30%]">
                    <div className="flex items-center gap-2">
                        <i className="fab fa-linux text-[20px] text-[#3a95d7]"></i>
                        <div className="font-semibold text-[20px]">Linux</div>
                    </div>

                    <div>
                        Commands and navigation in a Linux environment.
                        GitHub integration and app deployment.
                    </div>
                </Carousel.Slide>

                <Carousel.Slide className="flex flex-col gap-[20px] max-w-[30%]">
                    <div className="flex items-center gap-2">
                        <i className="fab fa-discord text-[20px] text-[#3681ea]"></i>
                        <div className="font-semibold text-[20px]">Discord Bots</div>
                    </div>

                    <div>
                        Crafts interactive bots for engaging Discord communities.
                    </div>
                </Carousel.Slide>

                <Carousel.Slide className="flex flex-col gap-[20px] max-w-[30%]">
                    <div className="flex items-center gap-2">
                        <i className="fab fa-telegram text-[20px] text-[#559bcc]"></i>
                        <div className="font-semibold text-[20px]">Telegram Bots</div>
                    </div>

                    <div>
                        Crafts efficient Telegram bots for streamlined communication.
                    </div>
                </Carousel.Slide>
            </Carousel>
        </Animated>
    </div>
}

export default SkillsSection;