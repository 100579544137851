import ClientReviews from "../components/landing/ClientReviews";
import ContactForm from "../components/landing/ContactForm";
import FAQSection from "../components/landing/FAQSection";
import Footer from "../components/landing/Footer";
import HeroSection from "../components/landing/HeroSection";
import Navigation from "../components/landing/Navigation";
import SkillsSection from "../components/landing/SkillsSection";
import WhatIOffer from "../components/landing/WhatIOffer";

function LandingPage() {
    return <div>
        <Navigation />
        <HeroSection />
        <SkillsSection />
        <WhatIOffer />
        <ClientReviews />
        <FAQSection />
        <Footer />
    </div>
}

export default LandingPage;